<template>
    <div>
        <b-alert
            :show="alertShow"
            variant="danger"
        >
            <span v-html="message"></span>
        </b-alert>
    </div>
</template>
<script>
import qs from "qs";
import ConstantService from "@/services/ConstantService";

export default {
    data() {
        return {
            message: null,
            alertShow: false
        }
    },
    async created() {
        this.messages(this.$i18n.locale)
    },
    watch: {
        'this.$i18n.locale': {
            handler(value) {
                this.messages(value)
            }
        }
    },
    methods: {
        messages(lang) {
            const config = {
                params: {
                    key: lang == 'tr' ? "student_absences_withconsents_information_tr" : "student_absences_withconsents_information_en"
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };
            ConstantService.getValue(config)
                .then(response => {
                    if (response.data.data != null) {
                        this.alertShow = true
                        this.message = response.data.data
                    } else {
                        this.alertShow = false
                    }
                }).catch(err => {
                this.showErrors(err)
            })
        },
    }
};
</script>
