import Api from '@/services/Index';

const sendSmsCode = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/persons/send/smscode/', formData);
}

const verifySmsCode = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/persons/verify/smscode/', formData);
}

const sendEmailCode = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/persons/send/emailcode/', formData);
}

const verifyEmailCode = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/persons/verify/emailcode/', formData);
}

const getAddress = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/person/address');
}

const patchAddress = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.patch('/person/address', formData);
}

const get = async (userId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/persons/'+userId);
}

export default {
    sendSmsCode,
    verifySmsCode,

    sendEmailCode,
    verifyEmailCode,

    getAddress,
    patchAddress,

    get
}
